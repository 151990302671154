/* eslint-disable react/jsx-key */
import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Box, Heading, Text, ThemeUIStyleObject } from 'theme-ui'
import Table from './table'

const VeganEnergyChart: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          relativePath: {
            in: [
              "images/logo.british-gas.png"
              "images/logo.edf.png"
              "images/logo.npower.png"
              "images/logo.sse.png"
              "images/logo.green-energy.png"
              "images/logo.good-energy.png"
              "images/logo.bulb.png"
              "images/logo.so-energy.png"
            ]
          }
        }
      ) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(quality: 60, layout: FULL_WIDTH, formats: [WEBP])
          }
        }
      }
    }
  `)

  const wrapper: ThemeUIStyleObject = {
    paddingY: [14, 15, 16],
    paddingX: [11, 13, 16],
  }

  const heading: ThemeUIStyleObject = {
    marginBottom: 12,
    textAlign: 'center',
  }

  const table: ThemeUIStyleObject = {
    marginX: 'auto',
  }

  return (
    <Box sx={wrapper}>
      <Heading as="h2" sx={heading} variant="lrg">
        Is your energy contaminated?
      </Heading>
      <Table
        sx={table}
        data={[
          ['Energy supplier', 'Animal waste in fuel mix', 'Feedstock details'],
          [
            <GatsbyImage
              image={
                data.allFile.nodes.find(
                  (node: { name: string }) => node.name === 'logo.british-gas'
                ).childImageSharp.gatsbyImageData
              }
              backgroundColor="ffffff"
              alt="Chart showing which energy companies are vegan"
            />,
            <Text variant="lrg">Yes</Text>,
            'Poultry litter and slurry',
          ],
          [
            <GatsbyImage
              image={
                data.allFile.nodes.find(
                  (node: { name: string }) => node.name === 'logo.edf'
                ).childImageSharp.gatsbyImageData
              }
              backgroundColor="ffffff"
              alt="Chart showing which energy companies are vegan"
            />,
            <Text variant="lrg">Yes</Text>,
            'Poultry manure',
          ],
          [
            <GatsbyImage
              image={
                data.allFile.nodes.find(
                  (node: { name: string }) => node.name === 'logo.npower'
                ).childImageSharp.gatsbyImageData
              }
              backgroundColor="ffffff"
              alt="Chart showing which energy companies are vegan"
            />,
            <Text variant="lrg">Yes</Text>,
            'Animal processing by-products',
          ],
          [
            <GatsbyImage
              image={
                data.allFile.nodes.find(
                  (node: { name: string }) => node.name === 'logo.sse'
                ).childImageSharp.gatsbyImageData
              }
              backgroundColor="ffffff"
              alt="Chart showing which energy companies are vegan"
            />,
            <Text variant="lrg">Yes</Text>,
            'Cattle slurry',
          ],
          [
            <GatsbyImage
              image={
                data.allFile.nodes.find(
                  (node: { name: string }) => node.name === 'logo.good-energy'
                ).childImageSharp.gatsbyImageData
              }
              backgroundColor="ffffff"
              alt="Chart showing which energy companies are vegan"
            />,
            <Text variant="lrg">Yes</Text>,
            'Animal manures',
          ],
          [
            <GatsbyImage
              image={
                data.allFile.nodes.find(
                  (node: { name: string }) => node.name === 'logo.green-energy'
                ).childImageSharp.gatsbyImageData
              }
              backgroundColor="ffffff"
              alt="Chart showing which energy companies are vegan"
            />,
            <Text variant="lrg">Yes</Text>,
            'Cattle slurry',
          ],
        ]}
        variant="fill"
      />
      <Text sx={{ mt: 10, textAlign: 'center' }} variant="intro">
        This is based on the biomass REGOs that each of the suppliers has
        redeemed for their 22/23 fuel mix (report from Ofgem)
      </Text>
      <Text sx={{ mt: 12, textAlign: 'center' }} variant="intro">
        Feedstocks confirmed on the UK Biogas Map or AD plant&apos;s company
        website
      </Text>
    </Box>
  )
}

export default VeganEnergyChart
