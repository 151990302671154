import React from 'react'
import { Text, Link, ThemeUIStyleObject } from 'theme-ui'

const SupportHeader: React.FC = () => {
  const container: ThemeUIStyleObject = {
    maxWidth: '100%',
    padding: '1rem',
    backgroundColor: 'background',
    textAlign: 'center',
    borderBottom: 'muted',
  }

  return (
    <div sx={container}>
      <Text>
        Already a customer?{' '}
        <Link href={process.env.GATSBY_ONLINE_ACCOUNT_URL}>Log in</Link> or{' '}
        <Link href={`${process.env.GATSBY_ONLINE_ACCOUNT_URL}/registration`}>
          create an online account
        </Link>
      </Text>
    </div>
  )
}

export default SupportHeader
