import { Page, MaybeGatsbyImageProps, ContentfulIdx } from './types'

export const formatPageLink = (page?: Page, level = 4): string => {
  if (page?.url) return page.url
  if (page?.slug === 'home') return '/'
  const topic = page?.topic || {}
  const context = topic.context || {}
  const forms = page?.__typename === 'ContentfulForm' && 'forms'
  const created = topic.slug === 'our-news' && page?.createdAtTimestamp
  const year = created && new Date(created).getFullYear()
  const parts = [context.slug, topic.slug, forms, year, page?.slug]
  return '/' + parts.filter(Boolean).slice(0, level).join('/')
}

//function to format contentful index nav elements
export const formatContentfulIdxToLink = (page: ContentfulIdx) => {
  return `/${page?.slug}`
}

export const formatJoinLink = (
  partner?: string,
  clickid?: string,
  wgu?: string | null,
  wgexpiry?: string | null,
  link = process.env.GATSBY_JOIN_URL
): string => {
  let urlParams = ''

  if (partner && partner !== 'webgains') {
    urlParams = `?partner=${partner}`
  }

  if (partner === 'webgains' && clickid) {
    urlParams = `?partner=${partner}&clickId=${clickid}&wgu=${wgu}&wgexpiry=${wgexpiry}`
  }

  return link + urlParams
}

export const removeDuplicates = <T extends unknown>(array: T[]): T[] =>
  array.filter((item, index) => array.indexOf(item) === index)

export const cleanSortCode = (sortCode: string): string =>
  sortCode.replace(/-/g, '')

export const trimLeft = (str = '', chars = '\\s'): string =>
  str.replace(new RegExp(`^[${chars}]+`), '')

export const trimRight = (str = '', chars = '\\s'): string =>
  str.replace(new RegExp(`[${chars}]+$`), '')

export const trim = (str = '', chars = '\\s'): string =>
  trimRight(trimLeft(str, chars), chars)

export const isFluid = (x: MaybeGatsbyImageProps): boolean =>
  x?.gatsbyImageData?.layout === 'fullWidth'

export const isFixed = (x: MaybeGatsbyImageProps): boolean =>
  x?.gatsbyImageData?.layout === 'fixed'
