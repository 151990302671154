import React from 'react'
import { Button, Modal } from 'react-bootstrap'

interface Props {
  isOpen: boolean
  paymentMonth: string
  onClose: () => void
}

const ModalComponent: React.FC<Props> = ({ isOpen, paymentMonth, onClose }) => {
  return (
    <Modal
      show={isOpen}
      onHide={onClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Meter Reading Submitted</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Thank you for submitting your Microtricity meter reading, you will
        receive payment for this in {paymentMonth}.{' '}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalComponent
