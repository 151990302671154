import React from 'react'
import { Box, Heading, Label, Select, Text, Textarea } from 'theme-ui'
import { useForm, UseFormMethods } from 'react-hook-form'
import { SymmetricValidatingInput } from './inputs/validating-input'
import { validAccountNumber } from './utils/validators'
import DatePicker from './inputs/date-picker'
import { validEmail, validPhoneNumber } from './utils/validators'
import AddressPicker, { AddressPickerFormData } from './inputs/address-picker'
import { BusinessMeterReadForm, MeterRead } from './utils/form-types'
import Checkbox from './inputs/checkbox'
import WebForm from './webform'
import { heading, fieldset, paragraph } from './utils/styles'
import Tooltip from './tooltip'

const completed = (): JSX.Element => (
  <Box sx={fieldset}>
    <Heading sx={heading}>Thanks for your meter read</Heading>
    <Text as="p" sx={paragraph}>
      {`Thanks for your meter reading for your business. It helps us to keep your
      account up-to-date and your bills as accurate as they can be. If you can
      give us another reading – in three to four months time – that'd be great.`}
    </Text>
    <Text as="p" sx={{ fontWeight: 'bold' }}>
      Best wishes,
    </Text>
    <Text as="p" sx={{ marginBottom: '1rem', fontWeight: 'bold' }}>
      The Ecotricity Team
    </Text>
  </Box>
)

type BusinessFormData = {
  readonly businessName: string
  readonly emailAddress: string
  readonly phoneNumber: string
  readonly line1: string
  readonly line2: string
  readonly line3: string
  readonly postcode: string
  readonly town: string
  readonly county: string
  readonly accountNumber: string
  readonly readingDate: string
  readonly numberOfMeters: string
  readonly revisedBill: string
  readonly comments: string
  readonly meterReadings: ReadonlyArray<MeterRead>
  readonly address: AddressPickerFormData
}

const reformatBusinessMeterRead = ({
  businessName,
  phoneNumber,
  emailAddress,
  address: { line1, line2, line3, postcode, town, county, udprn },
  accountNumber,
  readingDate,
  numberOfMeters,
  revisedBill,
  comments,
  meterReadings,
}: BusinessFormData & {
  readonly meterReadings: ReadonlyArray<MeterRead>
}): BusinessMeterReadForm => ({
  webform: {
    type: 'meterReadingBusiness',
    formData: {
      address: {
        address1: line1,
        address2: line2,
        address3: line3,
        town,
        county,
        postcode,
        udprn,
      },
      businessName,
      emailAddress,
      phoneNumber,
      accountNumber,
      readingDate,
      numberOfMeters: Number(numberOfMeters),
      revisedBill: Boolean(revisedBill),
      comments,
      meterReadings,
    },
  },
})

const DayReadTooltip = () => (
  <span>
    {`You may just have one reading on your meter. If that's you, just fill in the
    day read field. If you have two readings on your meter, please provide us
    with both of these readings in the day and night fields below`}
    <br />
    <br />
    {`Some meters may have numbers in red, we won't use these but you are welcome
    to include them in your reading.`}
  </span>
)

const RevisedBillTooltip = () => (
  <div>
    Tick the box to the left if you want us to re-issue your last bill to your
    reading, or leave blank if you want us to use your reading to make your next
    bill more accurate.
  </div>
)

const MeterReading = ({
  index,
  ...formFns
}: UseFormMethods<BusinessFormData> & {
  readonly index: number
}): JSX.Element => {
  const requiredField = (label: string, params = {}) =>
    formFns.register({
      required: `Please enter your ${label.toLowerCase()}`,
      ...params,
    })

  /**
   * Validates meter reads to 20 numbers and two decimal places
   * @param {string} fieldName
   * @returns {function(string): boolean|string}
   */
  const validMeterRead =
    (fieldName: string) =>
    (str: string | undefined): boolean | string =>
      str && /^[0-9]{0,20}(?:\.\d{0,2})?$/.test(str)
        ? true
        : `Please enter a valid ${fieldName.toLowerCase()}`

  return (
    <Box sx={fieldset}>
      <Heading sx={heading}>Meter {index + 1}</Heading>
      <Box sx={{ width: ['100%', '50%'] }}>
        <SymmetricValidatingInput
          {...formFns}
          deepName={['meterReadings', index, 'electricityDayValue'] as const}
          handleRef={(label: string) =>
            requiredField(label, {
              validate: {
                validMeterRead: validMeterRead(label),
              },
            })
          }
          label="Electricity day read"
          tooltip={DayReadTooltip}
        />
        <SymmetricValidatingInput
          {...formFns}
          deepName={
            ['meterReadings', index, 'electricityMeterSerialNumber'] as const
          }
          handleRef={requiredField}
          label="Serial no."
        />
        <SymmetricValidatingInput
          {...formFns}
          deepName={['meterReadings', index, 'electricityNightValue'] as const}
          handleRef={(label: string) =>
            requiredField(label, {
              validate: {
                validMeterRead: validMeterRead(label),
              },
            })
          }
          label="Electricity night read"
        />
        <SymmetricValidatingInput
          {...formFns}
          deepName={['meterReadings', index, 'gasValue'] as const}
          handleRef={(label: string) =>
            requiredField(label, {
              validate: {
                validMeterRead: validMeterRead(label),
              },
            })
          }
          label="Gas meter read"
        />
        <SymmetricValidatingInput
          {...formFns}
          deepName={['meterReadings', index, 'gasMeterSerialNumber'] as const}
          handleRef={requiredField}
          label="Gas serial no."
        />
      </Box>
    </Box>
  )
}
const BusinessMeterRead = (): JSX.Element => {
  const formFns = useForm<BusinessFormData>({
    criteriaMode: 'all',
    mode: 'onBlur',
    reValidateMode: 'onChange',
  })

  const { register, watch } = formFns
  const numberOfMeters = Number(watch('numberOfMeters'))
  const meterInputCount = Math.max(
    1,
    Number.isNaN(numberOfMeters) ? 1 : numberOfMeters
  )

  const requiredField = (label: string) =>
    register({ required: `Please enter your ${label}` })

  const meterReadings: JSX.Element[] = [
    ...new Array(meterInputCount).keys(),
  ].map((_, i) => {
    // eslint-disable-next-line react/no-array-index-key
    return <MeterReading index={i} key={i} {...formFns} />
  })

  return (
    <WebForm<BusinessMeterReadForm, BusinessFormData>
      reformat={reformatBusinessMeterRead}
      completeText={completed()}
      {...formFns}
    >
      <Box sx={fieldset}>
        <Heading sx={heading}>Your details</Heading>
        <SymmetricValidatingInput
          {...formFns}
          deepName={['businessName'] as const}
          handleRef={requiredField}
          label="Business name"
        />
        <SymmetricValidatingInput
          {...formFns}
          deepName={['emailAddress'] as const}
          handleRef={(label: string) =>
            register({
              required: `Please enter your ${label}`,
              validate: {
                validEmail: validEmail(label),
              },
            })
          }
          label="Email address"
          type="email"
        />
        <SymmetricValidatingInput
          {...formFns}
          deepName={['phoneNumber'] as const}
          handleRef={(label: string) =>
            register({
              required: `Please enter your ${label}`,
              validate: {
                validPhoneNumber: validPhoneNumber(label),
              },
            })
          }
          label="Phone number"
          type="tel"
        />
        <SymmetricValidatingInput
          {...formFns}
          deepName={['accountNumber'] as const}
          handleRef={(label: string) =>
            register({
              required: `Please enter your ${label}`,
              validate: {
                validAccountNumber: validAccountNumber,
              },
            })
          }
          label="Account number"
        />
        <AddressPicker {...formFns} addressName="address" />
      </Box>
      <Box sx={fieldset}>
        <Heading sx={heading}>Your reading</Heading>
        <Text as="p">Date of meter reading</Text>
        <DatePicker
          errorMessage="Please enter a date for these meter readings"
          name="readingDate"
          required={true}
          {...formFns}
        />
        <Label htmlFor="numberOfMeters">Number of meters</Label>
        <Select
          id="numberOfMeters"
          name="numberOfMeters"
          ref={register()}
          sx={{ width: ['100%', '50%'] }}
        >
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
        </Select>
      </Box>
      {meterReadings}
      <Box sx={fieldset}>
        <Heading sx={heading}>Receive a revised bill</Heading>
        <Box sx={{ display: 'flex', flexFlow: 'row nowrap' }}>
          <Checkbox {...formFns} label="Yes please" name="revisedBill" />
          <Tooltip contents={RevisedBillTooltip} />
        </Box>
        <Label htmlFor="comments">Additional comments or requests</Label>
        <Textarea id="comments" name="comments" ref={register()} />
      </Box>
    </WebForm>
  )
}

export default BusinessMeterRead
