import React, { useEffect, useState } from 'react'
import { Global } from '@emotion/core'
import { ThemeUIStyleObject } from 'theme-ui'
import { global } from '../styles/global'
import { fluidScale } from '../utils/mixins'
import { MastheadVariants, Page } from '../utils/types'
import useAccessibleOutline from '../hooks/use-accessible-outline'
import usePartnerPromo from '../hooks/use-partner-promo'
import '../styles/fonts.css'

import Header from './header'
import SEO from './seo'
import Masthead from './masthead'
import SubNav from './sub-nav'
import PartnerPromo from './partner-promo'
import Main from './main'
import Footer from './footer'
import CookieBanner from './cookie-banner'
import CookieSettingsBanner from './cookie-settings-banner'
import CarbonCalculatorFooter from './carbon-calculator/footer'
import CarbonCalculatorV2Footer from './carbon-calculator-v2/footer'
import { CookiesProvider } from 'react-cookie'
import { useCookieCompliance } from '../hooks/use-cookie-compliance'
import { cookiePreferences } from '../../types'

interface Props {
  page?: Page
  meta?: [
    {
      name: string
      content: string
    }
  ]
  muted?: boolean
  masthead?: MastheadVariants
  subnav?: {
    largeButtons: boolean
  }
  isolate: boolean
  simple: boolean
  simpleV2: boolean
  subPages?: Array<GatsbyTypes.SupportSubPageQuery>
  parent?: GatsbyTypes.SupportPageQuery
}

/**
 * Site-wide root layout component
 */
const Layout: React.FC<Props> = ({
  page,
  meta,
  muted,
  masthead,
  subnav,
  isolate,
  simple,
  simpleV2,
  subPages,
  children,
  parent,
}) => {
  const outline = useAccessibleOutline()
  const partner = usePartnerPromo()

  const [ready, setReady] = useState(false)
  const [shouldShowCookieBanner, setShouldShowCookieBanner] = useState(true)
  const { areUserCookiePrefsSet, setCookiePreferences } = useCookieCompliance()
  const [shouldShowSettingsBanner, setShouldShowSettingsBanner] =
    useState(false)

  useEffect(() => {
    setReady(true)
    setShouldShowCookieBanner(!areUserCookiePrefsSet)
  }, [setReady, setShouldShowCookieBanner, areUserCookiePrefsSet])

  const header: ThemeUIStyleObject = {
    position: 'sticky',
    top: 0,
    width: '100%',
    height: fluidScale('7.9rem', 0.8, 6.5),
    boxShadow: `0px 0.5rem 0px 0px rgba(0,0,0,0.04)`,
    zIndex: 4,
  }

  const headerFallback = {
    'height ': '7.9rem',
  }

  const nav: ThemeUIStyleObject = {
    position: 'sticky',
    top: fluidScale('7.8rem', 0.8, 6.5),
    zIndex: 2,
  }

  const navFallback = {
    'top ': '7.8rem',
  }

  const onOkayClicked = () => {
    setShouldShowCookieBanner(false)
    const cookieCatergoryPrefs: cookiePreferences = {
      marketing: 'granted',
      analytics: 'granted',
    }

    setCookiePreferences(cookieCatergoryPrefs)
  }

  const onSettingsClicked = () => {
    setShouldShowCookieBanner(false)
    setShouldShowSettingsBanner(true)
  }

  const onPrefsClicked = (marketing: boolean, analytics: boolean) => {
    const cookieCatergoryPrefs: cookiePreferences = {
      marketing: marketing === true ? 'granted' : 'denied',
      analytics: analytics === true ? 'granted' : 'denied',
    }

    setCookiePreferences(cookieCatergoryPrefs)
    setShouldShowSettingsBanner(false)
  }

  const businessPages = ['for-your-business', 'green-energy-for-smes']

  const isBusiness: boolean =
    page?.slug && businessPages.includes(page?.slug) ? true : false

  return (
    <>
      <CookiesProvider>
        <Global styles={{ ...global, ...outline }} />
        <CookieBanner
          ready={ready}
          active={shouldShowCookieBanner}
          onOkayClicked={onOkayClicked}
          onSettingsClicked={onSettingsClicked}
        />
        <CookieSettingsBanner
          ready={ready}
          settingsBanner={shouldShowSettingsBanner}
          onPrefsClicked={onPrefsClicked}
        />
        {!isolate && (
          <Header
            logoOnly={simple || simpleV2}
            sx={{ ...headerFallback, ...header }}
            isBusiness={isBusiness}
          />
        )}
        {page ? (
          <SEO
            title={page?.title}
            description={page?.description}
            image={page?.image}
            meta={meta}
          />
        ) : null}
        {masthead && !isolate && (
          <Masthead parent={parent} page={page} variant={masthead} />
        )}
        {subnav && !isolate && (
          <SubNav
            sx={{ ...navFallback, ...nav }}
            config={subnav}
            page={page}
            parent={parent}
            subPages={subPages}
          />
        )}
        {partner && page?.partnerPromotion && (
          <PartnerPromo partner={partner} />
        )}
        <Main blankBackground={simple || simpleV2} muted={muted}>
          {children}
        </Main>
        {!isolate && !simple && !simpleV2 && <Footer page={page} />}
        {!isolate && simple && <CarbonCalculatorFooter />}
        {!isolate && simpleV2 && <CarbonCalculatorV2Footer />}
      </CookiesProvider>
    </>
  )
}

export default Layout
