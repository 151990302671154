import React from 'react'
import { Grid, Text, ThemeUIStyleObject, Heading } from 'theme-ui'
import { ReactNodeArray } from 'react'
import { Stack } from 'raam'
import { Document, renderRichText } from '../rich-text'

interface Props {
  topic?: string
  heading?: string
  subheading?: string
  standfirst?: string
  text?: Document
}

/**
 * Presents the topic, title, subtitle and standfirst on every standard
 * article page
 */
const PageHeader: React.FC<Props> = ({
  topic,
  heading,
  subheading,
  standfirst,
  text,
}) => {
  const grid: ThemeUIStyleObject = {
    gridTemplateColumns: [
      '[start] repeat(12, 1fr) [end]',
      null,
      '1fr [start] repeat(10, 1fr) [end] 1fr',
    ],
    rowGap: 10,

    // horizontal rule
    '::before': {
      content: topic && '""',
      display: ['none', null, 'block'],
      borderTop: 'double',
      position: 'relative',
      top: '50%',
    },
  }

  const topicStyle: ThemeUIStyleObject = {
    gridColumn: 'start / end',
    letterSpacing: '0.4em',
  }

  const headingStyle: ThemeUIStyleObject = {
    gridColumn: 'start / end',
    fontSize: [6, 7],
  }

  const subheadingStyle: ThemeUIStyleObject = {
    gridColumn: 'start / end',
    marginTop: -9,
  }

  const standfirstStyle: ThemeUIStyleObject = {
    gridColumn: 'start / end',
    marginTop: 12,
  }

  const paragraphs: ReactNodeArray | undefined = standfirst
    ?.split('\n')
    .filter(Boolean)
    .map((p, i) => (
      <Text key={i} as="p" variant="intro">
        {p}
      </Text>
    ))

  return (
    <Grid sx={grid} variant="default">
      <Text sx={topicStyle} variant="primary">
        {topic}
      </Text>
      {heading && (
        <Heading as="h1" sx={headingStyle} variant="xl">
          {heading}
        </Heading>
      )}
      {subheading && (
        <Heading as="h2" sx={subheadingStyle} variant="head">
          {subheading}
        </Heading>
      )}
      {standfirst && (
        <Stack sx={standfirstStyle} gap={11}>
          {paragraphs}
        </Stack>
      )}
      {text && (
        <Stack sx={standfirstStyle} gap={11}>
          {renderRichText(text)}
        </Stack>
      )}
    </Grid>
  )
}

export default PageHeader
