import React from 'react'
import { Box, Container, ThemeUIStyleObject, useThemeUI } from 'theme-ui'
import { fluidScale } from '../utils/mixins'
import FooterUtilities from './footer-utilities'
import FooterPartners from './footer-partners'
import ClimateClockWidget from './climate-clock-widget'
import FooterPromo from './footer-promo'
import FooterSitemap from './footer-sitemap'
import QuoteWidget from './quote-widget'
import bgWebp from '../images/footer-bg.webp'
import bgJpg from '../images/footer-bg.jpg'
import useWebpImage from '../hooks/use-webp-image'
import { Page } from '../utils/types'
interface Props {
  page?: Page
}

const showClimateClock = true

const Footer: React.FC<Props> = ({ page }) => {
  const { theme } = useThemeUI()
  const bgHeight = fluidScale('9rem', 0.7, 10)(theme)
  const bg = useWebpImage(bgWebp, bgJpg)

  const footer: ThemeUIStyleObject = showClimateClock
    ? {
        backgroundColor: 'muted',
      }
    : {
        backgroundColor: 'muted',
        backgroundImage: `url(${bg})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center bottom',
        backgroundSize: `100% ${bgHeight}`,
        paddingBottom: bgHeight,
        overflow: 'hidden',
      }

  const footerFallback = showClimateClock
    ? {}
    : { 'backgroundSize ': `100% 9rem`, 'paddingBottom ': '9rem' }

  const section: ThemeUIStyleObject = {
    paddingY: 16,
    paddingX: [8, 16],
    backgroundColor: 'background',
    textAlign: 'center',
  }

  const climateChangeSection: ThemeUIStyleObject = {
    paddingTop: 16,
    paddingBottom: 0,
    paddingX: 0,
  }

  const divider: ThemeUIStyleObject = {
    marginY: 2,
  }

  return (
    <Box as="footer" sx={{ ...footerFallback, ...footer }}>
      {page?.topic?.slug === 'our-green-energy' &&
        (!page?.tags || page?.tags?.every(tag => tag?.slug !== 'landing')) && (
          <Box sx={{ ...section, ...divider }}>
            <Container>
              <QuoteWidget sx={{ maxWidth: '40rem', mx: 'auto' }} />
            </Container>
          </Box>
        )}
      <Box sx={{ ...section, ...divider, py: 0 }}>
        <Container variant="wide">
          <FooterPromo />
        </Container>
      </Box>
      <Box sx={{ ...section, ...divider }}>
        <Container>
          <FooterPartners />
        </Container>
      </Box>
      {/* <Box sx={{ ...section, ...divider }}>
        <Container>
          <FooterSignup />
        </Container>
      </Box> */}
      <Box sx={{ ...section, ...divider }}>
        <Container variant="wide">
          <FooterSitemap />
        </Container>
      </Box>
      <Box sx={{ ...section, ...divider }}>
        <Container variant="wide">
          <FooterUtilities />
        </Container>
      </Box>
      {showClimateClock && (
        <Box sx={{ ...section, ...divider, ...climateChangeSection }}>
          <Container variant="full">
            <ClimateClockWidget />
          </Container>
        </Box>
      )}
    </Box>
  )
}

export default Footer
