import React from 'react'
import { Box, Heading, Grid, ThemeUIStyleObject, Text, Link } from 'theme-ui'

import { Stack } from 'raam'
import { ReactNode } from 'react'
import { GatsbyImage, GatsbyImageProps } from 'gatsby-plugin-image'
import useWebpImage from '../hooks/use-webp-image'

import appIconImageWebp from '../images/app-icon.webp'
import appIconImagePng from '../images/app-icon.png'
import appStoreImage from '../images/app-store.gif'
import googlePlayImage from '../images/play-store.gif'
import movingHomeBadgeWebp from '../images/moving-home.webp'
import movingHomeBadgePng from '../images/moving-home.png'
import contourBackground from '../images/contour-bg.gif'
import sprayEdgeWebp from '../images/spray-edge-2.webp'
import sprayEdgePng from '../images/spray-edge-2.png'

import {
  BLOCKS,
  Node,
  Document,
  renderRichText,
  renderBlockLink,
  toString,
  CustomRenderer,
  renderInlineLink,
  renderGroups,
  BlockNode,
  getGroup,
  Group,
} from '../rich-text'

import {
  fluidScale,
  gridTemplateAreas as rows,
  repeatGridArea as cols,
} from '../utils/mixins'
import PageLink from './page-link'

interface Props {
  title?: string
  body?: Document
  aside?: Document
  screenshot: GatsbyImageProps
}

const AppPromo: React.FC<Props> = ({ title, body, aside, screenshot }) => {
  const appIconImage = useWebpImage(appIconImageWebp, appIconImagePng)
  const movingHomeBadge = useWebpImage(movingHomeBadgeWebp, movingHomeBadgePng)
  const sprayEdge = useWebpImage(sprayEdgeWebp, sprayEdgePng)

  const wrapper: ThemeUIStyleObject = {
    position: 'relative',
  }

  const badge: ThemeUIStyleObject = {
    position: 'absolute',
    display: ['none', null, 'block'],
    width: fluidScale('12rem', 0.7, 9.5),
    height: fluidScale('12rem', 0.7, 9.5),
    top: '-5.5rem',
    right: 0,
    backgroundImage: `url(${movingHomeBadge})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
  }

  // prettier-ignore
  const grid: ThemeUIStyleObject = {
    gridTemplateAreas: [
      rows([
        [cols(12, 'body')],
        [cols(12, 'aside')],
      ]),
      null,
      rows([
        [cols(6, 'body'), null, cols(5, 'aside')],
      ]),
      rows([
        [cols(5, 'body'), cols(2, 'media'), null, cols(4, 'aside')],
      ]),
    ],
    alignItems: ['flex-start', null, 'center'],
  }

  const columns = {
    body: {
      gridArea: 'body',
      position: 'relative',

      // layout
      paddingLeft: [0, '12%', 'calc(6.5rem + 12%)'],
      paddingRight: [0, '12%', '18%'],
      paddingY: [0, 16],

      // app icon
      '&::before': {
        content: '""',
        position: 'absolute',
        width: '5rem',
        height: '5rem',
        top: 16,
        left: '12%',
        backgroundImage: ['none', null, `url(${appIconImage})`],
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        filter: 'drop-shadow(1px 3px 3px rgba(0,0,0,0.4))',
      },

      // contour background
      backgroundImage: ['none', `url(${contourBackground})`],
      backgroundSize: 'cover',

      // spray edging
      '&::after': {
        content: '""',
        position: 'absolute',
        height: 0,
        bottom: 0,
        left: 0,
        width: '100%',
        paddingBottom: 'calc(100% * 252 / 1055)',
        backgroundImage: `url(${sprayEdge})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        transform: 'scaleX(-1) translate(10%, 65%)',
      },
    },
    media: {
      gridArea: 'media',
      marginX: '-30%',
    },
    aside: {
      gridArea: 'aside',
    },
  }

  const heading: ThemeUIStyleObject = {
    marginBottom: 13,
    textAlign: 'center',
  }

  const appStoreButton: ThemeUIStyleObject = {
    display: 'block',
    width: '12.2em',
    height: 'calc(12.2rem / (442 / 150))',
    backgroundImage: `url(${appStoreImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '12.2rem',
  }

  const googlePlayButton: ThemeUIStyleObject = {
    display: 'block',
    width: '12.2em',
    height: 'calc(12.2rem / (442 / 150))',
    backgroundImage: `url(${googlePlayImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '12.2rem',
  }

  const renderGroup = (group: Group, i: number): ReactNode => (
    <Stack
      key={i}
      sx={{ mt: group.prev === 'link' ? 11 : 0 }}
      gap={group.type === 'link' ? 8 : 11}
    >
      {group.children}
    </Stack>
  )

  const renderLink = (node: Node): ReactNode => {
    switch (node.nodeType) {
      case 'hyperlink':
        if (node.data.uri.includes('apple.com')) {
          return <Link sx={appStoreButton} href={node.data.uri} />
        }
        if (node.data.uri.includes('google.com')) {
          return <Link sx={googlePlayButton} href={node.data.uri} />
        }
    }
    return renderInlineLink(node)
  }

  const renderers: CustomRenderer = {
    renderNode: {
      [BLOCKS.DOCUMENT]: (node, children) =>
        renderGroups(node as BlockNode, children, getGroup, renderGroup),

      [BLOCKS.PARAGRAPH]: (node, children) =>
        renderBlockLink(node, renderLink) || (
          <Text as="p" variant="intro">
            {children}
          </Text>
        ),

      [BLOCKS.QUOTE]: node => (
        <Text as="blockquote" variant="sml">
          {toString(node)}
        </Text>
      ),
    },
  }

  return (
    <Box sx={wrapper}>
      <PageLink sx={badge} to="/support/moving-home" />
      {title && (
        <Heading as="h2" sx={heading} variant="lrg">
          {title}
        </Heading>
      )}
      <Grid sx={grid} variant="default">
        <Stack sx={columns.body as ThemeUIStyleObject} gap={11}>
          {body && renderRichText(body, renderers)}
        </Stack>
        <GatsbyImage
          sx={columns.media}
          image={screenshot.gatsbyImageData}
          backgroundColor="ffffff"
          alt="Mobile phone showing meter reading form on the Ecotricity app"
        />
        <Stack sx={columns.aside} gap={13}>
          {aside && renderRichText(aside, renderers)}
        </Stack>
      </Grid>
    </Box>
  )
}

export default AppPromo
