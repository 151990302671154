import React from 'react'
import { Box, Heading, Image, Link, Text, ThemeUIStyleObject } from 'theme-ui'
import { useBreakpointIndex } from '@theme-ui/match-media'

import appStoreIcon from '../images/app-store.svg'
import playStoreIcon from '../images/google-play-badge.svg'
import ecoIcon from '../images/eco-icon.svg'

import VideoCarousel from './video-carousel'

const textStyling: ThemeUIStyleObject = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: 'auto auto 1rem',
  textAlign: 'center',
}

const headingStyling: ThemeUIStyleObject = {
  ...textStyling,
  fontSize: ['1.75rem', '2.25rem'],
}

const descriptionHeadingStyling: ThemeUIStyleObject = {
  ...textStyling,
  margin: ['2rem auto 0.5rem', '1rem auto 0.5rem'],
}

const descriptionStyling: ThemeUIStyleObject = {
  ...textStyling,
  margin: ['auto auto 1rem', '2rem auto 1rem'],
}

const imageDivStyling: ThemeUIStyleObject = {
  display: 'flex',
  justifyContent: 'space-between',
  maxWidth: ['20rem', '29rem'],
  alignItems: 'center',
  margin: '2rem auto',
  flexDirection: ['row', 'initial'],
  flexWrap: ['wrap', 'initial'],
}

const imageStyling: ThemeUIStyleObject = {
  flex: ['0 1 100%', 'auto'],
  textAlign: ['center', 'initial'],
}

const appIcon: ThemeUIStyleObject = {
  height: ['auto', '3.25rem'],
  width: ['11rem', 'auto'],
}

const mobileCarousel: ThemeUIStyleObject = {
  marginTop: '1.5rem',
}

const ecoUrl = 'https://www.ecotricity.co.uk/'
const appStoreUrl = 'https://apps.apple.com/gb/app/ecotricity/id986732377'
const playStoreUrl =
  'https://play.google.com/store/apps/details?id=com.ecotricity.onlineaccount&hl=en_GB&gl=US'

interface Video {
  title?: string
  url?: string
}

interface Props {
  title?: string
  description?: string
  buttonText?: string
  buttonUrl?: string
  videos?: Array<Video>
}

const AppHelp: React.FC<Props> = ({
  title,
  description,
  buttonText,
  buttonUrl,
  videos,
}: Props) => {
  const breakpoint = useBreakpointIndex({ defaultIndex: 2 })
  const isMobile = breakpoint === 0

  return (
    <>
      <Heading sx={headingStyling} as="h3" variant="med">
        {title}
      </Heading>
      {isMobile && (
        <>
          <Box sx={mobileCarousel}>
            <VideoCarousel
              description={description}
              buttonText={buttonText}
              buttonUrl={buttonUrl}
              videos={videos}
            />
          </Box>
          <Heading sx={descriptionHeadingStyling} as="h4" variant="sml">
            Download the Ecotricity app
          </Heading>
          <Text sx={descriptionStyling} variant="intro">
            {description}
          </Text>
          <Box sx={imageDivStyling}>
            <Link sx={imageStyling} href={playStoreUrl} target="_blank">
              <Image
                sx={appIcon}
                src={playStoreIcon}
                alt="Google Play Store logo"
              />
            </Link>
            <Link sx={imageStyling} href={appStoreUrl} target="_blank">
              <Image
                sx={appIcon}
                src={appStoreIcon}
                alt="Apple App Store logo"
              />
            </Link>
          </Box>
        </>
      )}

      {!isMobile && (
        <>
          <Text sx={descriptionStyling} variant="intro">
            {description}
          </Text>
          <Box sx={imageDivStyling}>
            <Link sx={imageStyling} href={ecoUrl} target="_blank">
              <Image sx={appIcon} src={ecoIcon} alt="Ecotricity mini logo" />
            </Link>
            <Link sx={imageStyling} href={playStoreUrl} target="_blank">
              <Image
                sx={appIcon}
                src={playStoreIcon}
                alt="Google Play Store logo"
              />
            </Link>
            <Link sx={imageStyling} href={appStoreUrl} target="_blank">
              <Image
                sx={appIcon}
                src={appStoreIcon}
                alt="Apple App Store logo"
              />
            </Link>
          </Box>
          <VideoCarousel
            description={description}
            buttonText={buttonText}
            buttonUrl={buttonUrl}
            videos={videos}
          />
        </>
      )}
    </>
  )
}

export default AppHelp
